<template>
	<div>
		<router-view  />
	</div>
</template>

<script>
	export default {
		data() {
			return {
			};
		},
		watch: {
		},
		methods: {
		},
	};
</script>

<style lang='scss'>
</style>
